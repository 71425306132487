<template>
  <div class="home">
    <header>
      <img src="../assets/karma.svg" />
    </header>
    <karmameter />
    <Expenses />
    <toolbar />
    <expense-form v-if="showEpenseForm"></expense-form>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapGetters } from "vuex"

import Expenses from '@/components/Expenses.vue'
import ExpenseForm from '@/components/ExpenseForm.vue'
import Karmameter from '@/components/Karmameter.vue'
import Toolbar from '@/components/Toolbar.vue'

export default {
  name: 'home',
  computed: {
    ...mapGetters({
      showEpenseForm: "showExpenseForm"
    })
  },
  components: {
    Expenses,
    ExpenseForm,
    Toolbar,
    Karmameter
  }
}
</script>

<style lang="scss" scoped>
.home {
  // background: #fc0;
  height: 100vh;
  height: -webkit-fill-available;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  // padding-bottom: 22px;
  

  header {
    text-align: center;
    color: #fff;
    img {
      width: 88px;
    }
    padding: 16px;
  }
}
</style>
