<script>

import { HorizontalBar, mixins } from 'vue-chartjs'
import moment from 'moment'
import { Timestamp } from '../db'

const { reactiveProp } = mixins

export default {
  extends: HorizontalBar,
  mixins: [reactiveProp],
  props: ['chartData'],
	data: function(){
		return {
			options: {
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					xAxes: [{
						stacked: true,
						gridLines: {display:false}
					}],
					yAxes: [{
						stacked: true,
						gridLines: {display:false}
					}],
				}
			}
		}
	},
	methods: {
		months: function() {
			var months = [];
			let now = moment()
			for(var i = 0; i < 6; i++) {
				months.push(now.clone().subtract(i,'month').format('MMM YY'))
			}
			return months
		},

		dataForMe: function() {
			return this.expenses.map(e => e.mine)
		},
		dataForThem: function() {
			return this.expenses.map(e => e.their)
		},
	},
	watch: {
		expenses: function(){
			console.log(this.expenses)
			this.renderChart(this.chartdata, this.options)	
		}
	},
	mounted () {
		this.renderChart(this.chartData, this.options)
	},

}
</script>