<template>
	<form class="addexpense">
		<div class="input"> 
			<input v-model.trim="expense.title" placeholder="Bezeichnung" />
		</div>
		<div class="input"> 
			<input type="number" inputmode='decimal' v-model.number="expense.value" />
		</div>
		<div class="input"> 
			<button @click.prevent="save" class="btn-add" >+ hinzufügen</button>
		</div>
	</form>
</template>

<script>
//import { log } from 'util'
import { mapGetters } from "vuex"

export default {
	name: 'expense-form',
	data() {
		return {
			expense: {
				title: null,
				value: 0.0
			}
		}
	},
	computed: {
		...mapGetters({
      		user: "user"
    	})
	},
	methods: {
		save() {

			this.$store.dispatch('addExpense', this.expense).then(_ => {
				this.$store.commit('showExpenseForm', false);
				this.expense = {
					title: null,
					value: 0.0
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.addexpense {
	background: #fff;
	padding: 32px;
	box-shadow: 0px -2px 4px rgba(0,0,0,0.1);
	
	width: 100vw;
	position: fixed;
	bottom: 0;

	box-sizing: border-box;
	z-index: 999;

	h1 {
		margin: 0;
		padding: 0;
		font-weight: 400;
		font-size: 28px;
		margin-bottom: .5em;
	}

	.input {
		padding: 10px 0;
		display: flex;
		input {
			flex: 1;
			background: #f2f1f1;
			border: 0;			
			box-sizing: border-box;
			font-size: 18px;
			line-height: 44px;
			padding: 10px;
		}
	}

	button {
		background: #ce0d59;
		border: 0;
		font-size: 18px;
		line-height: 44px;
		padding: 8px;
		width: 100%;
		color: #fff;
	}
}
</style>
