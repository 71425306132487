<template>
  <div id="toolbar" v-if="$store.state.user.authenticated">
	  <!-- <a href="#" @click.prevent="signout()">abmelden</a> -->
	  <a href="#" @click.prevent="showExpenseForm()" class="show-expense-form"><font-awesome-icon icon="plus" /></a>
  </div>
</template>

<script>

import { auth } from '../db'

export default {
	name: 'toolbar',

	methods: {
		signout: function(){
			auth.signOut()
        	.then(() => {
          		this.$router.replace({
            		name: "signin"
          		})
        	})
		},
		showExpenseForm() {
			this.$store.commit('showExpenseForm', true);
		}
	}
}
</script>

<style type="sass" scoped>
	#toolbar {
		/* background: #fff; */
		position: fixed;
		bottom: 0;
		width: 100vw;
		height: 44px;
	}
	.show-expense-form {
		background: #fff;
		color: #ce0d59;
		position: absolute;
		width: 88px;
		height: 88px;
		display: flex;
		align-items: center;
  		justify-content: center;

		bottom:10px;
		left: 50%;
		margin-left: -44px;
		font-size: 44px;
		border-radius: 44px;

		box-shadow: 0 0 10px rgba(0,0,0,.2);


	}
</style>