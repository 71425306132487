<template>
	<div class="wrap" v-if="updateExists">Aktualisierung verfügbar <button class="btn" @click="refreshApp">aktualisieren</button></div>
</template>

<script>
import update from '@/mixins/update'
export default {
	mixins: [update],
}
</script>
<style lang="scss" scoped>
.wrap {
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	padding: 8px;

}
/* General button style */
.btn {
  border: none;
  font-size: inherit;
  color: inherit;
  background: silver;
  cursor: pointer;
  padding: 0 8px;
  line-height: 24px;
  border-radius: 8px;
//   display: inline-block;
//   margin: 15px 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  outline: none;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  margin: 0 8px;
}
</style>