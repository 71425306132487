<template>
  <div id="meter">	
	<div class="bar">
		<div class="user_a segment" :style="'width: '+calc.perc[0]+'%'">{{calc.mine | numeralFormat('$0,0.00')}}</div><div class="user_b segment" :style="'width: '+calc.perc[1]+'%'">{{calc.their |  numeralFormat('$0,0.00')}}</div>
		<div :class="{total: true, green: calc.diff >= 0, red: calc.diff < 0}">{{ calc.diff | numeralFormat('$0,0.00') }}</div>
	</div>	
  </div>
</template>

<script>
//import { log } from 'util';
import _ from 'lodash'

export default {
	name: 'karmameter',
	data(){
		return {
			calc: {
				sum: 0,
				mine: 0,
				their: 0,
				perc: 0,
				diff: 0
			}
		}
	},

	computed: {
		uid() {
			return this.$store.getters.user.data.uid
		},
		expenses() {
			return this.$store.state.expenses
		},
	},
	watch: {
		expenses(val) {

			let sum = 0
			let mine = 0
			let their = 0

			for(let e of this.expenses) {
				sum += e.value
				if(!e.user.id) {
					if(e.user.indexOf(this.uid) > -1) mine += e.value
					if(e.user.indexOf(this.uid) < 0) their += e.value
				} else {
					if(e.user.id == this.uid) mine += e.value
					if(e.user.id != this.uid) their += e.value
				}
			}

			let a = Math.round((mine/ sum) * 100)
			let b = Math.round((their / sum) * 100)
			let perc = [a,b]

			let diff =  -((sum / 2) - mine)

			this.calc = {
				sum: sum,
				mine: mine,
				their: their,
				perc: perc,
				diff: diff
			}
		
		}
	}

}
</script>

<style lang="scss" scoped>
#meter {
	position: relative;
	padding: 16px 16px 32px;
	// padding: 32px 16px 16px;
	// margin-top: 44px;
	
}
.bar {
	display: flex;
	position: relative;

	border-radius: 22px;
	box-shadow: 0 0 10px rgba(#000, .1);

	.segment {
		line-height: 22px;
		padding: 8px 22px;
		font-weight: 600;
	}
}
.user_a {
	width: 50%;
	background: #ffcf00;
	border-top-left-radius: 22px;
	border-bottom-left-radius: 22px;
	text-align: left;
}
.user_b {
	width: 50%;
	background: #f15e3a;
	border-top-right-radius: 22px;
	border-bottom-right-radius: 22px;
	text-align: right;

}
.total {
	box-shadow: 0 0 10px rgba(#000, .1);
	box-sizing: border-box;
	position: absolute;
	left: 50%;
	top: 8px;
	background: #fff;
	border-radius: 11px;
	width: 88px;
	height: 22px;
	margin-left: -44px;
	// margin-top: -19px;
	line-height: 22px;
	text-align: center;
	font-weight: 600;
	font-size: 12px;
	padding: 0 10px;
	&.green {
		color: green;		
	}
	&.red {
		color: red;
	}
}
</style>