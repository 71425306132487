import Vue from 'vue'
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import firebase from 'firebase/app'
import { db } from '../db'
import mutations from './mutations'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    expenses: [],
    groups: [],
    users: [],
    user: {
      authenticated: false,
      data: null
    },
    showExpenseForm: false
  },
  getters: {
    user(state) {
      return state.user
    },
    showExpenseForm(state) {
      return state.showExpenseForm
    }
  },
  mutations: { ...vuexfireMutations, ...mutations} ,
  actions: {
    bindExpenses: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef('expenses', db.collection('expenses').orderBy('created_at', 'desc'))
    }),
    bindGroups: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef('groups', db.collection('groups'))
    }),
    bindUsers: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef('users', db.collection('users'))
    }),
    addExpense: firestoreAction( (context, expense) => {      
      expense.user = db.collection('users').doc(context.state.user.data.uid)
      expense.created_at =  firebase.firestore.FieldValue.serverTimestamp()
      return db.collection('expenses').add(expense)
    }),
    deleteExpense: firestoreAction( (context, expenseId) => {      
      return db.collection('expenses').doc(expenseId).delete()
    }),

    fetchUser({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null);
      if (user) {
        commit("SET_USER", {
          displayName: user.displayName,
          email: user.email,
          uid: user.uid
        });
      } else {
        commit("SET_USER", null);
      }
    }
    
  },
  modules: {
  }
})
