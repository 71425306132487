<template>
  <div id="app">
    <snackbar />
    <router-view/>
  </div>
</template>

<script>

import toolbar from '@/components/Toolbar.vue'
import Snackbar from '@/components/Snackbar.vue'
// import { log } from 'util'

export default {
  components: {
    toolbar,
    Snackbar
  },
  created(){
    this.$store.dispatch('bindExpenses')
    // this.$store.dispatch('bindGroups')
    this.$store.dispatch('bindUsers')
  }
}
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600&display=swap');
  body {
    // background: #f2f1f1;
    // background-image: url(assets/wave.svg), linear-gradient(0deg, #FEE140 0%, #FA709A 100%);
    // background-repeat: no-repeat, no-repeat;
    // background-size: auto auto, 100vw 200px;
    // background-position: 0 120px, 0 0;

    background-image: linear-gradient(0deg, #ffcf00 0%, #f15e3a 100%);
    font-family: 'Source Sans Pro', sans-serif;
  }
  #app {
    height: 100vh;
    height: -webkit-fill-available;
    box-sizing: border-box;
    overflow: hidden;
  }
</style>
