import { db } from '../db'

export default {
	SET_LOGGED_IN(state, value) {
		state.user.authenticated = value;
	},
	SET_USER(state, data) {
		state.user.data = data;
	},
	showExpenseForm(state, value) {
		state.showExpenseForm = value
	}
}