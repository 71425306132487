<template>
  <div class="expenses" style="padding-bottom: 7em;">
    <div v-for="month in months" :key="month.monthStr" style="margin-bottom: 8px;">
      <h2 @click="toggleShow(month.monthStr)" style="padding-bottom: 8px;position: relative; align-items: center;justify-content: space-between;display:flex;font-size: 18px;  background-image: linear-gradient(90deg, #ffcf00 0%, #f15e3a 100%); border-top-left-radius: 8px; border-bottom-right-radius: 8px; margin: 8px; padding: 8px;box-shadow: 0 0 10px rgba(#000, .1);">
        {{month.monthStr}}
        <svg v-if="show.indexOf(month.monthStr) > -1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
        </svg>
        <svg v-if="show.indexOf(month.monthStr) == -1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
        </svg>
        <mini-meter :sum="month.sum" :mine="month.mine" :their="month.their" style="position: absolute; left:0; bottom: 0; width: 100%" />
      </h2>
      <div v-if="show.indexOf(month.monthStr) > -1">
        <div class="data">
          <span class="sum">{{month.sum | numeralFormat('$0,0.00')}}</span>
          <span class="mine">{{month.mine | numeralFormat('$0,0.00')}}</span>
          <span class="their">{{month.their | numeralFormat('$0,0.00')}}</span>
          <span :class="['diff',(-((month.sum / 2) - month.mine) > 0) ? 'pos' : 'neg']">{{-((month.sum / 2) - month.mine) | numeralFormat('$0,0.00')}}</span>
        </div>
        <div v-for="expense in month.expenses" :key="expense.id" class="expense" v-touch:swipe.left="swipeHandler(expense.id)"  v-touch:touchhold="touchholdHandler(expense.id)" :id="`expense_${expense.id}`">
          
            <div :class="['user', (expense.user.id == uid) ? 'current' : 'other']">{{expense.user | firstLetter}}</div>
            <div>
              <div class="date">{{expense.created_at | formatDate}}</div>
              <div class="title">{{expense.title}}</div>
            </div>
            <div class="value">{{expense.value | numeralFormat('$0,0.00')}}</div>
          
    
          <div v-if="activeId == expense.id" class="action">
            <button class="cancelBtn" @click.prevent="activeId = null"><div>abbrechen</div></button>
            <button class="deleteBtn" @click.prevent="deleteExpense(expense.id)"><div><font-awesome-icon icon="backspace" /> löschen</div></button>
          </div>
        </div>
      </div>
    </div>
    <div class="mma">
      <div>MIN {{mma[0]| numeralFormat('$0,0.00')}}</div>
      <div>MAX {{mma[1]| numeralFormat('$0,0.00')}}</div>
      <div>ø {{mma[2]| numeralFormat('$0,0.00')}}</div>
    </div>

    <div class="chart">
      <bar-chart :chart-data="chartData"/>
    </div>
  </div>
</template>

<script>

import moment from 'moment'
import { Timestamp } from '../db'
//import { log } from 'util'

import _ from 'lodash'

import BarChart from '@/components/BarChart.vue'
import MiniMeter from '@/components/MiniMeter.vue'


export default {
  name: 'expenses',
  components: {
    BarChart,
    MiniMeter
  },
  data() {
	  return {
      show: [],
      months: [],
      activeId: null,
      mma: [0,0,0]
    }
  },
  computed: {
	  expenses: function(){
		  return this.$store.state.expenses
    },
    uid: function() {
      return this.$store.getters.user.data.uid
    },
    
    chartData: function() {
      let lastsix = this.months//this.months_.take(this.months, 12)
      return {
        labels: lastsix.map(m => m.monthStr),
        datasets: [
          {
            label: 'Ich',
            backgroundColor: '#ffcf00',
            data: lastsix.map(e => e.mine)
          },
          {
            label: 'Andere',
            backgroundColor: '#f15e3a',
            data: lastsix.map(e => e.their)
          }
        ]
      }
    }
  },

  filters: {
    formatDate: function(date) {
      
      if(date && date.seconds) {
        return moment(new Timestamp(date.seconds,0).toDate()).format('DD.MM.YY HH:mm')
      } else {
        return ""
      }
    },
    firstLetter: function(item) {
      if(item && item.displayName && item.displayName[0]) {
        return item.displayName[0]
      } else {
        return ""
      }      
    }
  },

  methods: {
    swipeHandler: function(eId) {
      return (dir) => {
        console.log(eId);
        this.activeId = eId;
      }
    },
    touchholdHandler(eId) {
      return (e) => {
        console.log(eId);
        this.activeId = eId;
      }
    },
    toggleShow(monthStr) {      
      let idx = this.show.indexOf(monthStr)
      console.log(monthStr, idx, this.show)
      if( idx > -1) {
        this.show = _.remove(this.show, monthStr)
      } else {
        this.show.push(monthStr)
      }
    },
    deleteExpense(eId) {
      this.$store.dispatch('deleteExpense', eId).then(_ => {
				console.log('DELETED', eId)
			})
    }
  },
  created() {
	  
  },
  watch: {
    expenses(val, old) {
      console.log(val.length, old.length)
      
      //let expenses = this.$store.state.expenses

      //console.log(_.filter(expenses, e => { return e.user.id }))

      //if(this.expenses.length == 0) return []

      let oldest = _.last(val)

      let oldest_date = moment(new Timestamp(oldest.created_at.seconds,0).toDate())
      let months = []
     

      var total = 0
      var min = 0
      var max = 0
      
      let diff = Math.ceil(moment().diff(oldest_date, 'months',true))

      //console.log(moment().diff(oldest_date, 'months',true))

      for(var i = 0; i <= diff; i++) {
        let month = oldest_date.clone().add(i, 'months')
        
        let expenses = _.filter(val, e => {
          if(!e.created_at) return false
          return moment(new Timestamp(e.created_at.seconds,0).toDate()).isSame(month, 'month')
        })

        let sum = 0
        let mine = 0
        let their = 0

        for(let e of expenses) {          
          sum += e.value
          if(!e.user.id) {
			  		if(e.user.indexOf(this.uid) > -1) mine += e.value
			  		if(e.user.indexOf(this.uid) < 0) their += e.value
          } else {
            if(e.user.id == this.uid) mine += e.value
            if(e.user.id != this.uid) their += e.value
          }
        }
        

        // = _.filter(expenses, e => e.user.id == this.uid).reduce((a,b) => a+b.value,0)
        // for(let e of _.filter(this.expenses, e => e.user.id == this.uid)) {
        //   mine += e.value
        // }
        // let their = 0 // _.filter(expenses, e => e.user.id != this.uid).reduce((a,b) => a+b.value,0)
        // for(let e of _.filter(this.expenses, e => e.user.id != this.uid)) {
        //   their += e.value
        // }

        // let their = expenses.reduce((a,b) => {
        //   //if(b.user.id) console.log("their",b)

        //   if(!b.user.id && b.user.indexOf(this.uid) == -1) {
        //     return a + b.value
        //   }
        //   if(b.user.id && b.user != this.uid) {
        //     return a + b.value
        //   }
        //   return a
        // }, 0)

        //let their = 0

        //if(i == moment().diff(oldest_date, 'months')) this.show.push(month.format('MM.YYYY'))
        
        total += sum
        if(i == 0) { min = sum } else if(sum < min){ min = sum }
        if(sum > max) { max = sum }

        let data =  {
          month: month,
          monthStr: month.format('MM.YYYY'),
          expenses: expenses,
          sum: sum,
          mine: mine,
          their: their
        }

        //console.log({data})
        months.push(data)
      }
      
      this.months = _.orderBy(months, ["month"],["desc"])
      this.mma = [min,max,total/months.length] 
    }
  }
  
}
</script>

<style lang="scss" scoped>
.expenses {
  // max-height: 60vh;
  overflow: scroll;
  flex: 1;

  .expense {
    background: #fff;
    box-shadow: 0 0 10px rgba(#000, .05);
    border-radius: 8px;
    
    // margin: 16px 16px 16px 16+22px;
    margin: 8px 22px 8px 22px;
    padding: 0 8px;

    position: relative;
    display: flex;


    

    .user {
      width: 32px;
      height: 32px;
      background: #ffcf00;
      border-radius: 16px;
      display: flex;
      align-items: center;
  		justify-content: center;
      font-weight: 600;
      color: #fff;
      left: -16px;
      border: 2px solid #fff;
      box-sizing: border-box;

      box-shadow: 0 0 10px rgba(#000, .1);

      position: absolute;
      z-index: 1;
      &.other {
        background: #f15e3a;
        left: auto;
        right: -16px;
      }
    }

    .title {
      line-height: 24px;
      padding: 0 8px 8px;
      flex: 1;
    }
    .date {
      line-height: 12px;
      font-size: 12px;
      color: #ddd;
      padding: 8px 8px 0;
    }
    .value {
      line-height: 52px;
      padding: 0 8px;
      font-weight: 600;
      font-size: 22px;
      text-align: right;
      flex:1;
    }
    .action {
      background:rgba(255,255,255,.9);
      position: absolute;
      top: 0;
      right:0;
      left: 0;
      bottom: 0;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: inset 0px 0px 10px rgba($color: #000000, $alpha: .4);

      button {
        margin: 0 8px;
      }

      .cancelBtn {
        background: transparent;
        color: #000;
        border: 0;
        border-radius: 8px;
        padding: 2px 2px 6px 2px;
        & > div {
          color: #000;
          background: transparent;
          border-radius: 6px;
          padding: 10px;
          text-transform: uppercase;
          font-size: 16px;
        }
      }

      .deleteBtn {
        background: #ff0000;
        color: #fff;
        border: 0;
        border-radius: 8px;
        padding: 2px 2px 6px 2px;
        & > div {
          color: #ff0000;
          background: #fff;
          border-radius: 6px;
          padding: 10px;
          text-transform: uppercase;
          font-size: 16px;
        }
      }
    }
  }
}
.data {
  //background:rgba(255,255,255,.1);
  margin: -16px 8px 8px 8px;
  padding: 8px;
  padding-top: 16px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

  box-shadow: inset 0px 12px 16px rgba(0,0,0,.1);

  display: flex;
  align-items: center;
  justify-content: center;
  

  span {
    background:#fc0;
    border-radius: 12px;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    line-height: 24px;
    padding: 0 8px;
    margin: 0 4px;
    box-shadow: 0px 4px 4px rgba(0,0,0,.1);

    &.sum {
      background-color: #fff;	
      color: #000;
    }
    &.mine {
      background-color: #ffcf00;
    }
    &.their {
      background-color: #f15e3a;
    }
    &.diff {
      background-color: #fff;
      color: red;
      &.pos {
        color: green;
      }
    }
  }
}

.chart {
  background: #fff; margin: 8px; padding: 8px; border-radius: 0 0 8px 8px; margin-top: 0px;
}

.mma {
  background: #fff;
  border-radius: 8px 8px 0px 0px;
  
  margin: 24px 8px 2px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;

  div {
    line-height: 32px;
    background: #efefef;
    border-radius: 16px;
    padding: 0 16px;
    margin: 0 4px;
    font-size: 12px;
    font-weight: bold;
  }
}
</style>

