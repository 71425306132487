
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/analytics'

import store from '../store'



var firebaseConfig = {
  apiKey: "AIzaSyAGG1qzv8bl_IhdYsxzq63LoHvY0uNHMQU",
  authDomain: "karmasplit-fa7f9.firebaseapp.com",
  databaseURL: "https://karmasplit-fa7f9.firebaseio.com",
  projectId: "karmasplit-fa7f9",
  storageBucket: "karmasplit-fa7f9.appspot.com",
  messagingSenderId: "134296652191",
  appId: "1:134296652191:web:770e5e434ca0140e51d5aa",
  measurementId: "G-WK5TV6GH93"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

// firebase.firestore().enablePersistence()
//   .catch(function(err) {
//       if (err.code == 'failed-precondition') {
//           // Multiple tabs open, persistence can only be enabled
//           // in one tab at a a time.
//           // ...          
//       } else if (err.code == 'unimplemented') {
//           // The current browser does not support all of the
//           // features required to enable persistence
//           // ...
//       }
//   }).then(()=> {
//     console.log("PERSISTANCE")
//   })

  

// Get a Firestore instance

export const auth = firebase.auth()
export const db = firebase.firestore()

auth.onAuthStateChanged(user => {
  store.dispatch("fetchUser", user);
});

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { Timestamp, GeoPoint } = firebase.firestore
export { Timestamp, GeoPoint }

// if using Firebase JS SDK < 5.8.0
// db.settings({ timestampsInSnapshots: true })