import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { auth } from './db'
import './registerServiceWorker'

import 'normalize.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faSignInAlt, faUserPlus, faPlus, faBackspace } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faUserSecret, faSignInAlt, faUserPlus, faPlus, faBackspace)

Vue.component('font-awesome-icon', FontAwesomeIcon)


import VueNumerals from 'vue-numerals'
Vue.use(VueNumerals, {
  locale: 'de'
})

import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents)

Vue.config.productionTip = false

let app

auth.onAuthStateChanged(user => {
  if(!app){
    app = new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount('#app')
  }
})
