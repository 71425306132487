<template>
	<div class="bg">
		<div class="fg" :style="{width: `${width}%`}"></div>
		<div class="devider"></div>
	</div>
</template>
<script>
export default {
	props:['mine','their','sum'],
	computed: {
		width() {
			return Math.round((this.mine / this.sum)*100)
		}
	},
}
</script>
<style lang="scss" scoped>
.bg {
	background-color: #f15e3a;	
	height: 8px;
	border-bottom-right-radius: 8px;
}
.fg {
	width: 50%;
	height: 100%;
	background-color: #ffcf00;
}
.devider {
	width: 4px;
	height: 4px;
	border-radius: 100%;
	position: absolute;
	top: 2px;
	left: calc(50% - 2px);
	background-color: #fff;

}
</style>